import "./AboutHero.css"
import AboutImg from "../../../Assets/Images/Rectangle 16.png"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
const AboutHero = () => {
    var hugeScreen = window.matchMedia("(min-width:3025px)")
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("about")}  </p>

            </div>
            <div className={`container-fluid about-hero ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-md-4" style={{overflow:"hidden"}}> 
                        <a href="/new reel show room new intro.mp4" target="_blank" className="img">
                             <svg  viewBox="0 0 496.158 496.158" ><path style={{fill:"none"}} d="M496.158 248.085C496.158 111.063 385.089.003 248.083.003 111.07.003 0 111.063 0 248.085c0 137.001 111.07 248.07 248.083 248.07 137.006 0 248.075-111.069 248.075-248.07"/><path style={{fill:"#fff"}} d="M404.191 338.019c0 7.498-6.077 13.574-13.573 13.574H105.541c-7.497 0-13.574-6.076-13.574-13.574v-179.88c0-7.498 6.077-13.574 13.574-13.574h285.077c7.496 0 13.573 6.076 13.573 13.574z"/><path style={{fill:"#434485"}} d="m335.494 239.131-123.131-75.607c-3.361-2.063-7.785-2.154-11.225-.227a11.11 11.11 0 0 0-5.682 9.687V323.6c0 4.005 2.171 7.715 5.665 9.678a11.11 11.11 0 0 0 11.21-.199l123.129-75.01a11.17 11.17 0 0 0 5.324-9.459 11.16 11.16 0 0 0-5.29-9.479"/></svg>
                        </a>
                    </div>
                    <div className="col-md-8">
                        <div className="text">
                            <h2>{t("legacyBorn")}</h2>
                            <p>{t("aboutDesc")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutHero