import "./Portfolio.css";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { Footer, Header } from "../../Sections";
import { useTranslation } from "react-i18next";

// import $ from "jquery";
// import "turn.js";

const Portfolio = () => {
  const { t, i18n } = useTranslation();

  // const flipbookRef = useRef(null);

  // useEffect(() => {
  //   $(flipbookRef.current).turn({
  //     autoCenter: true,
  //     display: "single",
  //     resize: true,

  //   });

  //   return () => {
  //     $(flipbookRef.current).turn("destroy");
  //   };
  // }, []);

  // const pages = [];
  // for (let i = 1; i <= 66; i++) {
  //   pages.push(
  //     <div className="page" key={i}>
  //       <img src={`/portfolio/portfolio (${i}).jpg`} alt={`portfolio ${i}`} />
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>Profile</title>
        <meta
          name="description"
          content="Discover Al-jabriya's diverse projects, showcasing our expertise in delivering high-quality, innovative solutions across various industries and client needs"
        />
      </Helmet>

      <Header />

      <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
        <p className={`link link-project ${i18n.language == "ar" && "ar"}`}>
          <Link to="/">{t("Home")}</Link> / {t("Portfolio")}
        </p>
      </div>

      <section className="portfolio">
        <article>
          <iframe
            allowfullscreen="allowfullscreen"
            scrolling="no"
            className="fp-iframe"
            src="https://heyzine.com/flip-book/112f16c3f3.html"
            style={{ border: "0px", width: "100%" }}
          ></iframe>
        </article>
      </section>

      <div className="container-fuild videos about-hero">
        <div className="row">
          <h2>{t("Videos")}</h2>
          <div className="videos-list">

          <a
              href="https://www.youtube.com/watch?v=PmLu1H9i4EM"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-5.avif" alt="Throw back Ramadan 2017, Ramadan 2018 We Are Waiting" />
              </div>
              <div className="description">
                  <span>Throw back Ramadan 2017, Ramadan 2018 We Are Waiting</span>
              </div>
            </a>

            <a
              href="https://www.youtube.com/watch?v=bjbLMYyVFB0"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-6.avif" alt="ALKOUT MALL PROJECT PREPARATION" />
              </div>
              <div className="description">
                  <span>ALKOUT MALL PROJECT PREPARATION</span>
              </div>
            </a>

            <a
              href="https://www.youtube.com/watch?v=t7Ay7Jz0l04"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-7.avif" alt="Seminar held by AlJabriya" />
              </div>
              <div className="description">
                  <span>Seminar held by AlJabriya</span>
              </div>
            </a>

            <a
              href="https://www.youtube.com/watch?v=vpcCeoMNCn8"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-8.avif" alt="THE CAMP ALJABRIYA OFFICE FURNITURE & INTERIOR DESIGN" />
              </div>
              <div className="description">
                  <span>THE CAMP ALJABRIYA OFFICE FURNITURE & INTERIOR DESIGN</span>
              </div>
            </a>

            <a
              href="https://www.youtube.com/watch?v=-c2VfsfHico"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-1.avif" alt="KUWAIT FINANCE HOUSE" />
              </div>
              <div className="description">
                  <span>KUWAIT FINANCE HOUSE</span>
              </div>
            </a>

            <a
              href="https://www.youtube.com/watch?v=Vqa1VXm36I8"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-2.avif" alt="WELCOME TO AL JABRIYA OFFICE FURNITURE & INTERIORS" />
              </div>
              <div className="description">
                  <span>WELCOME TO AL JABRIYA OFFICE FURNITURE & INTERIORS</span>
              </div>
            </a>

            <a
              href="https://www.youtube.com/watch?v=0I3rYPR2EgQ"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-3.avif" alt="KUWAIT FINANCE HOUSE - INNOVATION_CENTER " />
              </div>
              <div className="description">
                  <span>KUWAIT FINANCE HOUSE - INNOVATION_CENTER </span>
              </div>
            </a>

            <a
              href="https://www.youtube.com/watch?v=UmK-a_FxxR8"
              target="_blank"
            >
              <div className="img">
                <img src="/videos/video-4.avif" alt="Yousif Hussain Law Firm group " />
              </div>
              <div className="description">
                  <span>Yousif Hussain Law Firm group </span>
              </div>
            </a>
          </div>
        </div>
      </div>

      <Footer background="none" />
    </>
  );
};

export default Portfolio;
