import "./Header.css"
import { motion, useAnimation } from "framer-motion"
import { useEffect, useState } from "react"
import Logo from "../../Assets/Images/logo.png"
import LogoAr from "../../Assets/Images/logo-ar.png"
import LogoEn from "../../Assets/Images/logo-en.png"
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux';
import i18next from "i18next";
import { useTranslation } from "react-i18next"
import Translation from "../../Components/Translation/Translation"
const Header = (props) => {
    const [isVisible, selIsVisible] = useState(false)
    const pageName = useSelector(state => state.DetailsSlice.pageName)
    const { t, i18n } = useTranslation()
    const navAnimation = useAnimation()
    const ulAnimation = useAnimation()
    var media = window.matchMedia("(max-width: 767px)")
    var ipad = window.matchMedia("(min-device-width : 768px) and (max-device-width : 1024px) ")
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem("lang"))
        navAnimation.start({
            width: media.matches == false && isVisible && ipad.matches == false ? "20%" :
                media.matches == true && isVisible && ipad.matches == false ? "50%" :
                    media.matches == false && isVisible && ipad.matches == true ? "40%" : "0%",
            paddingLeft: isVisible && i18n.language == "en" ? "60px" : "0px",
            paddingRight: isVisible && i18n.language == "ar" ? "60px" : "0px",
            transition: {
                duration: .2
            },
        })
        ulAnimation.start({
            width: isVisible ? "176px" : "0%",
            transition: {
                duration: .5
            },
        })

    }, [isVisible, i18n.language])
    return (
        <>
            <nav className={"container-fluid nav-jab" + " " + props.page}>
                <div className="logo-con">
                    {
                        props.page == "home" ?
                            <svg className="logo" xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136" fill="none">
                                <g clipPath="url(#clip0_456_2639)">
                                    <path d="M136 0H0V136H136V0Z" fill="#464380" />
                                    <path d="M64.2204 84.05H71.7804V87.12C71.7804 91.1884 73.3966 95.0902 76.2734 97.967C79.1502 100.844 83.052 102.46 87.1204 102.46C91.1889 102.46 95.0906 100.844 97.9674 97.967C100.844 95.0902 102.46 91.1884 102.46 87.12V33.54H93.9104V26H110V87.12C109.875 93.1119 107.407 98.8162 103.125 103.01C98.8433 107.203 93.0886 109.551 87.0954 109.551C81.1022 109.551 75.3476 107.203 71.0657 103.01C66.7838 98.8162 64.3156 93.1119 64.1904 87.12L64.2204 84.05Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_456_2639">
                                        <rect width="136" height="136" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg> : <Link to="/">
                                {
                                    i18n.language === "ar" ? (
                                        <img src={LogoAr} className="arabic-logo" alt="logo arabic image" />
                                    ) : (
                                        <img src={LogoEn} className="arabic-logo _en" alt="logo image"  />
                                    )
                                }
                            </Link>
                    }

                </div>
                <div className="burger-con" onClick={() => selIsVisible(!isVisible)}>
                    <span className="qodef-opener-icon qodef-m qodef-source--predefined qodef-side-area-mobile-header-opener">
                        <span className="qodef-m-icon qodef--open">
                            <svg className="qodef-svg--menu" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 45 15">
                                <line x1="0" y1="-3" x2="45" y2="-3" strokeWidth="2px"></line>
                                <line x1="0" y1="7.5" x2="45" y2="7.5" strokeWidth="2px"></line>
                                <line x1="0" y1="18.5" x2="45" y2="18.5" strokeWidth="2px"></line>
                            </svg>
                        </span>
                    </span>
                </div>
                <motion.div className={`menu ${i18n.language == "ar" && "ar"}`}
                    initial={{
                        width: "0%",
                        paddingLeft: i18n.language == "en" ? "0" : "unset",
                        paddingRight: i18n.language == "en" ? "unset" : "0",
                        paddingTop: media.matches == true && "30px"
                    }}
                    animate={navAnimation}>
                    <div className="close"
                        style={{
                            marginLeft: i18n.language == "ar" ? "20px" : "unset"
                        }}
                        onClick={() => selIsVisible(!isVisible)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="32" viewBox="0 0 49 49" fill="none">
                            <path d="M2 2L47 47M2 47L47 2" stroke="white" strokeWidth="3" />
                        </svg>
                    </div>
                    <div className={`main-menu ${i18n.language == "ar" && "ar"}`}
                    >
                        <motion.ul animate={ulAnimation}>
                            <li>
                                <Link to="/Design">{t("design")}</Link>
                            </li>
                            <li>
                                <Link to="/Fitout">{t("fitout")}</Link>
                            </li>
                            <li>
                                <Link to="/brands">{t("brands")}</Link>

                            </li>
                            <li>
                                <Link to="/Products">{t("products")}</Link>

                            </li>
                            <li>
                                <Link to="/Casestudy">{t("casestudy")}</Link>
                            </li>

                            <li>
                                <Link to="/Projects">{t("projects")}</Link>
                            </li>

                            <li>
                                <Link to="/Blog">{t("blog")}</Link>
                            </li>
                        </motion.ul>
                        <motion.ul animate={ulAnimation}>
                            <li>
                                <Link to="/AboutUs">{t("about")}</Link>
                            </li>
                            <li>
                                <Link to="/Career">{t("career")}</Link>
                            </li>
                            <li>
                                <Link to="/ContactUs">{t("contact")}</Link>
                            </li>
                            <Translation />
                        </motion.ul>
                    </div>
                </motion.div>
            </nav>
        </>
    )
}

export default Header