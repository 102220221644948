import "./AboutHero.css";
import AboutImg from "../../../Assets/Images/Rectangle 16.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isIOS } from 'react-device-detect';

const AboutHero = () => {
  var hugeScreen = window.matchMedia("(min-width:3025px)");
  const { t, i18n } = useTranslation();
  const videoUrl = isIOS
    ? `${process.env.PUBLIC_URL}/new-reel-show-room-new-intro.mov`
    : `${process.env.PUBLIC_URL}/new-reel-show-room-new-intro.mp4`;

  return (
    <>
      <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
        <p className={`link link-project ${i18n.language == "ar" && "ar"}`}>
          <Link to="/">{t("Home")}</Link> / {t("about")}{" "}
        </p>
      </div>
      <div
        className={`container-fluid about-hero ${
          i18n.language == "ar" && "ar"
        }`}
      >
        <div className="row">
          <div className="col-md-4" style={{ overflow: "hidden" }}>
            <a
              href="/video"
              target="_blank"
              className="img"
            >
              <svg viewBox="0 0 497 497">
                <path
                  d="M496.2 248.1C496.2 111.1 385.1 0 248.1 0S0 111.1 0 248.1s111.1 248.1 248.1 248.1 248.1-111.1 248.1-248.1"
                  style={{ fill: "none" }}
                />
                <path
                  d="M404.2 338c0 7.5-6.1 13.6-13.6 13.6H105.5C98 351.6 92 345.5 92 338V158.1c0-7.5 6-13.5 13.5-13.5h285.1c7.5 0 13.6 6 13.6 13.5z"
                  style={{ fill: "#fff" }}
                />
                <path
                  d="m306.7 243.1-70.5-43.3c-1.9-1.2-4.4-1.3-6.4-.1q-.8.4-1.4 1t-1 1.3-.6 1.5q-.3.8-.3 1.7v86.2c0 2.3 1.3 4.4 3.3 5.5q.7.5 1.5.7.9.2 1.7.1.8 0 1.7-.2.8-.2 1.5-.7l70.5-42.9q.7-.4 1.2-1 .6-.6 1-1.3t.6-1.5.2-1.6-.2-1.6-.6-1.5-1-1.3q-.5-.6-1.2-1"
                  style={{ fill: "#434485" }}
                />
              </svg>
            </a>
          </div>
          <div className="col-md-8">
            <div className="text">
              <h2>{t("legacyBorn")}</h2>
              <p>{t("aboutDesc")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHero;
