import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AboutUs, Blog, Brands, Career, CaseStudy, CaseStudyDetails, ContactUs, Cx, Design, Fitout, Home, NewBrandsPage, NewCaseStudy, OfficeDesks, Projects, SampleProject, SignlePost } from './Pages';
import Profile from './Pages/Portfolio/Portfolio';

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Project/:id" element={<SampleProject />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Products" element={<Brands />} />
          <Route path="/OfficeDesks" element={<OfficeDesks />} />
          <Route path="/product/:id" element={<Cx />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/CaseStudy" element={<CaseStudy />} />
          <Route path="/CaseStudyDetails" element={<CaseStudyDetails />} />
          <Route path="/Fitout" element={<Fitout />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Brands" element={<NewBrandsPage />} />
          <Route path="/CaseStudy/:id" element={<NewCaseStudy />} />
          <Route path="/SinglePost/:id" element={<SignlePost />} />
          <Route path="/portfolio" element={<Profile />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
