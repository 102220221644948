import "./Blog.css"
import { Header, Footer } from "../../Sections"
import Img from "../../Assets/Images/16.png"
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
const Blog = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [posts, setPosts] = useState()
    const [category_blog, setCategory_blog] = useState()
    const [categoryId, setCategoryId] = useState()
    const lis = document.querySelectorAll("li")
    useEffect(() => {
        axios.get(`https://api.al-jabriya.com/api/blog/all?category_blog_uuid=${categoryId ? categoryId : ""}`, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setPosts(res.data.data.blogs)
            })
            .catch(err => {
                console.log(err)
            })

    }, [categoryId, i18n.language])
    useEffect(() => {
        axios.get("https://api.al-jabriya.com/api/categories_blog/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setCategory_blog(res.data.data.category_blog)
            })
            .catch(err => {
                console.log(err)
            })
    }, [i18n.language])
    const handleCategory = (ID, e) => {
        setCategoryId(ID)
        lis.forEach(li => {
            li.classList.remove("active")
        })
        e.target.classList.add("active")
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    const handleNavigate = (id) => {
        navigate(`/SinglePost/${id}`, {
            state: {
                ID: id
            }
        })
    }
    return (
        <>
        <Helmet>
            <title>Blog</title>
            <meta name="description" content="At Al-jabriya, we select trending and advanced office furniture to provide clients with the latest ideas and technologies, fostering efficient and inspiring work environments." />
        </Helmet>
            <Header page="sample" />

            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("blog")} </p>
            </div>
            <div className={`container-fluid blog-con ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-12 col-md-9 col-lg-9 col-xl-9 col-scroll o-2">
                        {posts && posts.map(post => (
                            <div
                                className="card-blog" key={post.uuid}>
                                <img src={post.image} alt={post.title} onClick={() => handleNavigate(post.uuid)} />
                                <p className="date">{post.created_at}</p>
                                <h4>{post.title}</h4>

                                <p className="p-description">
                                    {post?.description?.slice(0, 300)}...
                                </p>
                                <div className="flex-aswad-between" >
                                    <div className="flex-aswad-center">
                                        <h5>{t("share")} </h5>
                                        <a className="facebook" style={{
                                            cursor: "pointer",outLine:"none",background:"transparent"
                                        }} href="http://www.facebook.com/sharer.php?u=https://al-jabriya.com/Blog" target="_blank"><FaFacebookF size={20} /></a>
                                        <a className="linked-in" style={{
                                            cursor: "pointer",outLine:"none",background:"transparent"
                                        }} href="https://www.linkedin.com/shareArticle?mini=true&url=https://al-jabriya.com/Blog" target="_blank"><FaLinkedinIn size={20} /></a>
                                    </div>

                                    <div className="flex-aswad-center" onClick={() => handleNavigate(post.uuid)} style={{
                                        cursor: "pointer"
                                    }}>
                                        <h3>{t("read")}</h3>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                            <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                        </svg>
                                    </div>

                                </div>
                            </div>
                        ))}
                        {/* <div className="card-blog">
                            <img src={Img} />
                            <p className="date">5 nov 2023</p>
                            <h4>al Jabriya new office</h4>
                            <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds, chanced upon a brochur Asfour, initiated it all when he, high above the clouds, chanced upon a brochurAsfour, initiated it all when he, </p>
                            <div className="flex-aswad-between">
                                <div className="flex-aswad-center">
                                    <h5>share </h5>
                                    <div className="facebook"><FaFacebookF size={20} /></div>
                                    <div className="linked-in"><FaLinkedinIn size={20} /></div>
                                </div>
                                <div className="flex-aswad-center">
                                    <h3>Read more</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                        <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                    </svg>
                                </div>

                            </div>
                        </div>
                        <div className="card-blog">
                            <img src={Img} />
                            <p className="date">5 nov 2023</p>
                            <h4>al Jabriya new office</h4>
                            <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds, chanced upon a brochur Asfour, initiated it all when he, high above the clouds, chanced upon a brochurAsfour, initiated it all when he, </p>
                            <div className="flex-aswad-between">
                                <div className="flex-aswad-center">
                                    <h5>share </h5>
                                    <div className="facebook"><FaFacebookF size={20} /></div>
                                    <div className="linked-in"><FaLinkedinIn size={20} /></div>
                                </div>
                                <div className="flex-aswad-center">
                                    <h3>Read more</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                        <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                    </svg>
                                </div>

                            </div>
                        </div> */}
                    </div>
                    <div className="col-12  col-md-3 col-lg-3 col-xl-3 o-1">
                        <div className="text">
                            <h4>{t("Categories")}</h4>
                            <ul>
                                {category_blog && category_blog.map(item => (
                                    <li
                                        onClick={(e) => handleCategory(item.uuid, e)}
                                    >
                                        {item.title}
                                    </li>
                                ))}
                                {/* <li>chair</li>
                                <li>Design</li>
                                <li>under construction</li>
                                <li>inspiration</li>
                                <li>challenges</li>
                                <li>solutions</li>
                                <li>completed</li>
                                <li>new Brand</li>
                                <li>site visit</li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer background="none" />
        </>
    )
}

export default Blog