import "./SampleProject.css"
import { Header, Footer, Slider, NextProject } from "../../Sections"
import Img from "../../Assets/Images/16.png"
import { useEffect, useState } from "react"
import axios from "axios"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useRef } from "react";
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet-async"

const SampleProject = () => {
    const params = useParams()
    const { i18n, t } = useTranslation()
    const [initialSlide, setInititalSlide] = useState(0)
    const sliderRef = useRef()
    const Location = useLocation()
    const [singleProject, setSingleProject] = useState()
    const [projectId, setProjectId] = useState(params.id)
    const [previous, setPrevious] = useState()
    const [next, setNext] = useState()
    var hugeScreen = window.matchMedia("(min-width:3025px)")
    const [show, setShow] = useState(false)
    const [status, setStatus] = useState(false)
    const [email, setEmail] = useState()
    const largeScreen = window.matchMedia("(min-width:1540px)")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate()

    const handleSubscribtion = () => {
        axios.post("https://api.al-jabriya.com/api/add/subscribe", {
            email
        })
            .then(res => {
                console.log(res)
                setStatus(true)
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        axios.post("https://api.al-jabriya.com/api/show/project", {
            project_uuid: projectId
        }, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                
                setInititalSlide(0)
                setSingleProject(res.data.data.project)
                axios.post("https://api.al-jabriya.com/api/projects/adjacent", {
                    project_uuid: res.data.data.project.uuid,
                    category_uuid: res.data.data.project.category.uuid
                }, {
                    headers: {
                        "Accept-Language": i18n.language
                    }
                })
                    .then(res => {

                        setInititalSlide(0)
                        setNext(res.data.data.next_project)
                        setPrevious(res.data.data.previous_project)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)

            })
        setInititalSlide(0)
    }, [projectId, i18n.language])
    const handleNext = () => {
        setProjectId(next.uuid)
        setInititalSlide(0)
        sliderRef.current.swiper.slideTo(0);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    const handlePrevious = () => {
        setProjectId(previous.uuid)
        setInititalSlide(0)
        sliderRef.current.swiper.slideTo(0);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    useEffect(() => {
        setInititalSlide(0)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    const handleNavigate = () => {
        navigate("/projects", {
            state: {
                uuid: singleProject?.category?.uuid,
                title: singleProject?.category?.title
            }
        })
    }
    return (
        <>
            <Helmet>
                  <title>Sample Project</title>
                <meta name="description" content="Discover Al-jabriya's sample projects, showcasing our expertise in delivering high-quality, innovative solutions tailored to meet diverse client needs." />
            </Helmet>
            <Header page="sample" />

            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-sample-project ${i18n.language == "ar" && "ar"}`}> <Link to="/">{t("Home")}</Link>  / <Link to="/projects">{t("projects")}</Link> / <span className="category-name " onClick={handleNavigate}>{singleProject?.category?.title}</span> / {singleProject && singleProject.title}</p>
            </div>

            <div className={`sample-hero sample-new-edit container-fluid ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-md-6" style={{
                        paddingLeft: ""
                    }}>
                        <div className="img" style={{
                            backgroundImage: `url(${singleProject && singleProject.main_image_in})`
                        }}>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text">
                            <h2>{singleProject && singleProject.title}</h2>
                            <ul>
                                <li>Client:  {singleProject && singleProject.client} </li>
                                <li>Description: {singleProject && singleProject.description}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                singleProject && singleProject.gallery.length > 0 ?
                    <>
                        <div className={`cx-slider rrr container-fluid px-0 ${i18n.language == "ar" && "ar"}`}>
                            <div className="next" onClick={() => sliderRef.current.swiper.slideNext()}>
                                <svg
                                    style={{
                                        cursor: "pointer"
                                    }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                    <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                                </svg>


                            </div>
                            <div className="prev" onClick={() => sliderRef.current.swiper.slidePrev()}>
                                <svg
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                                    <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" strokeWidth="2" />
                                </svg>
                            </div>
                            <Swiper
                                key={projectId}
                                ref={sliderRef}
                                modules={[Pagination]}
                                pagination={{ clickable: true }}
                                spaceBetween={50}
                                slidesPerView={1}
                                initialSlide={initialSlide}
                                loop={true}
                            >
                                {singleProject && singleProject.gallery.map(item => (
                                    <SwiperSlide>
                                        <div className="slide-img" style={{
                                            backgroundImage: `url(${item})`
                                        }}></div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        {/* <div className={`cx-slider cx-sample-project container cx-new-update px-0 ${i18n.language == "ar" && "ar"}`}>
                        <div className="next" onClick={() => sliderRef.current.swiper.slideNext()}>
                            <svg
                                style={{
                                    cursor: "pointer"
                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>
                        <div className="prev" onClick={() => sliderRef.current.swiper.slidePrev()}>
                            <svg
                                style={{
                                    cursor: "pointer"
                                }}
                                xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                                <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" strokeWidth="2" />
                            </svg>
                        </div>

                        <Swiper
                            ref={sliderRef}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            spaceBetween={50}
                            slidesPerView={1}
                            initialSlide={0}
                            loop={true}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {singleProject && singleProject.gallery.map(item => (
                                <SwiperSlide>
                                    <div className="slide-img"
                                        style={{
                                            backgroundImage: `url(${item})`
                                        }}></div>
                                </SwiperSlide>
                            ))}


                        </Swiper>

                    </div> */}
                    </>
                    : <></>
            }

            <div className={`container-fluid cx-next ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-6 prev-cx " >
                        {previous == undefined ? <></> :
                            <div className="flex-aswad-center" onClick={handlePrevious} style={{
                                cursor: "pointer"
                            }}>
                                <svg

                                    style={{
                                        cursor: "pointer"
                                    }}
                                    xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                                    <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" strokeWidth="2" />
                                </svg>
                                <div className="text text-prev">
                                    <h4>{previous && previous.title}</h4>
                                    <p>{t("PreviousProject")}</p>
                                </div>
                                <div className="img-next img-prev" style={{
                                    backgroundImage: `url(${previous && previous.image})`
                                }}></div>
                                {/* 
                            <img src={previous && previous.image} /> */}
                            </div>}
                    </div>
                    <div className="col-6 cx-prev ">
                        {next == undefined ? <></> :
                            <div className="flex-aswad-center" style={{
                                cursor: "pointer"
                            }} onClick={handleNext}>
                                <div className="img-next" style={{
                                    backgroundImage: `url(${next && next.image})`
                                }}></div>
                                {/* <img src={next && next.image} /> */}
                                <div className="text">
                                    <h4>{next && next.title}</h4>
                                    <p>{t("NextProject")}</p>
                                </div>
                                <svg

                                    style={{
                                        cursor: "pointer"
                                    }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" viewBox="0 0 40 77" fill="none">
                                    <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" strokeWidth="2" />
                                </svg>
                            </div>}


                    </div>
                </div>
            </div>
            <Footer background="none" />
        </>
    )
}

export default SampleProject