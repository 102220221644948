import React from 'react';
import ReactPlayer from 'react-player';
import "./VideoPlayer.css"

const VideoPlayer = () => {
  return (
    <div>
      <ReactPlayer
        url={`${process.env.PUBLIC_URL}/new-reel-show-room-new-intro.mp4`}
        controls
      />
    </div>
  );
};

export default VideoPlayer;